const questionList = [

{
	question: '1. Where did Kevin & Jewel first meet?',
	answers: ['At work', 'At university', 'At a cafe', 'At the gym'],
	answer: 1
},
{
	question: '2. Where did Kevin & Jewel go on their first date?',
	answers: ['Ice Skating', 'Art Museum', 'Bungee Jumping' ,'Disney Tsum Tsum Festival'],
	answer: 3
},
{
	question: "3. What has Kevin & Jewel NOT done as a date before?",
	answers: ['Pottery Workshop', 'Cafe hopping', 'Kayaking', 'Trampoline Park'],
	answer: 0
},
{
	question: '4. When did Kevin propose to Jewel?',
	answers: ['28 Jul 2023', '11 Nov 2023', '25 Dec 2023', '1 Jan 2024'],
	answer: 2
},
{
	question: '5. What does Kevin love most about Jewel?',
	answers: ['Her radiant eyes', 'Her intelligent mind', 'Her enormous heart', 'All of the above'],
	answer: 3
},
{
	question: '6. How many days have Kevin & Jewel known each other?',
	answers: ['21,675 days', '2,167 days', '216 days', '21 days'],
	answer: 1
},
{
	question: "7. Where is Kevin & Jewel's dream honeymoon destination?",
	answers: ['London', 'Paris', 'Switzerland', 'New Zealand'],
	answer: 3
},

]

module.exports = {
    questionList
}
